import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap } from "rxjs/operators";
import { OrganisationService } from "../core/services/organisation.service";
import { loadOrganisationList, loadOrganisationListSuccess } from "./organisation-list.actions";
import { ActivatedRoute } from "@angular/router";
import { Constants } from "../core/constants/constants";

@Injectable()
export class organisationListEffects {
    
    public currentYear: number

    constructor(
        private actions$: Actions,
        private organisationService: OrganisationService,
        private route: ActivatedRoute
    ) {
      this.route.params.subscribe(params => {
         this.currentYear = params.year || Constants.currentYear;
      }); 
    }

    loadOrganisationList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadOrganisationList),
            mergeMap(() => {
                return this.organisationService.getOrganisations(this.currentYear).pipe(
                    map(organisationlist => {
                        return loadOrganisationListSuccess({ organisationlist })
                    })
                )
            })
        )
    });
}