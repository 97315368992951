import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { RolesAndProductsForm } from './roles-products-form-layout';
import { UsersService } from 'src/app/core/services/users.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Constants } from 'src/app/core/constants/constants';

@Component({
  selector: 'app-roles-products-form',
  templateUrl: './roles-products-form.component.html',
  styleUrls: ['./roles-products-form.component.scss']
})
export class RolesProductsFormComponent implements OnChanges {

  @Input() public roles:any;
  @Input() public products:any;  
  @Input() public section:any;  
  @Output() public tableDataFromFormToUsersRolesAndProducts = new EventEmitter<any>();
  
  darkMode$: Observable<boolean>;

  public form: FormGroup;
  public rolesAndProductsForm: Array<IDynamicFormBlock> = RolesAndProductsForm;
  public blocks: Array<IDynamicFormBlock> = this.rolesAndProductsForm
  public yearList: any[];
  public currentYear: number
  
  public selection = {
    roles: [],
    products: [],
    year: null
  }

  constructor(
    private store: Store<{ darkMode: boolean }>,
    private fb: FormBuilder,
    private usersService: UsersService,
    private route: ActivatedRoute
  ) {
    this.darkMode$ = store.select('darkMode'),
    this.route.params.subscribe(params => {
      this.currentYear = params.year || Constants.currentYear;
    });
    this.getDefaultYearList() 
   }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.form) {
      this.form = this.toFormGroup(this.blocks);
    } 
    if (changes.roles && changes.roles.currentValue) {
      this.getRoles();
    }
    if (changes.products && changes.products.currentValue) {
      this.getProducts();
    }    
    if (this.form) {
      this.setYearDropdownOptions()
      this.subscribeToFormChanges();   
    }
  }

  public getDefaultYearList() {
    this.yearList = [];
    for (var i = 2013; i <= new Date().getFullYear() + 1; i++) {
      this.yearList.push({name: i, id:i});
    }
    this.yearList.push({name: '—', id: null});
    this.yearList.reverse();
  }

  public setYearDropdownOptions() {
    this.rolesAndProductsForm[2].options = this.yearList  
  }

  public getRoles() {
    if(this.roles.length) {
      this.rolesAndProductsForm[0].options = this.roles  
    } 
  }

  public getProducts() {
    if (this.products.length) {
      this.rolesAndProductsForm[1].options = this.products
    }
  }

  private toFormGroup(blocks: any): FormGroup {
    // Instantiate FormGroup
    const formGroup: FormGroup = this.fb.group({});
     // Check each block type and add controls
    blocks.forEach((block) => {  
      if (block.blockType === 'array') {
        // Create form array for array blocks
        const formArray = this.toFormArray(block.options);
        formGroup.addControl(block.dataKey, formArray);
      } else {
        // Create form control for other types of blocks
        formGroup.addControl(block.dataKey, this.fb.control(''));
      }
    }); 
    // Return completed group
    return formGroup;
  }

  private toFormArray(options: any): FormArray {
    // Instantiate FormArray
    const formArray: FormArray = this.fb.array([]);  
    // Add FormControls to array based on options
    options.forEach(option => {
      formArray.push(this.fb.control(option.value));
    });  
    // Return array back to FormGroup
    return formArray;
  }

  private subscribeToFormChanges(): void {
    this.form.valueChanges.subscribe(val => {
      this.trackSelections()      
    });
  }

  trackSelections() {
    let selectedRoles = this.form.get('role')?.value || [];
    let selectedProducts = this.form.get('product')?.value || [];
    let selectedYear = this.form.get('yearIdDrop')?.value || [];
    this.selection = {
      roles: selectedRoles,
      products: selectedProducts,
      year: selectedYear
    }
  }

  resetForm() {
    this.form.get('role')?.reset([]);
    this.form.get('product')?.reset([]);
    this.form.get('yearIdDrop')?.reset([]);
  }

  public createRolesTableData(selection: any) {
    const tableData = selection.products.map(product => ({
      product: product,
      roles: selection.roles.filter(role => role.productId === product.id),
      year: selection.year
    }));
    this.tableDataFromFormToUsersRolesAndProducts.emit(tableData);
  }

  onSubmit() {
    this.createRolesTableData(this.selection)
    this.resetForm()
  }

}
