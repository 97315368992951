import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/core/constants/constants';

@Component({
  selector: 'app-roles-products-table',
  templateUrl: './roles-products-table.component.html',
  styleUrls: ['./roles-products-table.component.scss']
})
export class RolesProductsTableComponent implements OnInit {

  
  @Input() public removeActionColumn:boolean;
  @Input() public section:string;
  @Input() public tableData:any;
  @Input() public roles:any;
  @Input() public products:any;
  @Output() public resetTableData = new EventEmitter<any>();
  darkMode$: Observable<boolean>;

  constructor(
    private store: Store<{ darkMode: boolean }>
  ) { 
    this.darkMode$ = store.select('darkMode')
  }


  ngOnInit(): void {}

  getProductName(productId: number): string {
    const product = this.products.find(p => p.id === productId);
    return product ? product.name : 'Unknown Product';
  }

  getRoleNames(roleIds: number[]): string {
    const roleNames = roleIds.map(roleId => {
      const role = this.roles.find(r => r.id === roleId);
      return role ? role.name : 'Unknown Role';
    });
    return roleNames.join(', ');
  }

  getYear(year: number | null) {
    const yearValue = year[0] ? year[0] : '—' 
    return yearValue
  }

  deleteRow(index: number): void {
    this.tableData.splice(index, 1);
    if (this.tableData.length < 1) {
      this.tableData = null
      this.resetTableData.emit()
    }
  }

}
